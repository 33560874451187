.blog-section-container {
  margin: 40px 40px 40px 120px;
}

.blog-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.blog-search-and-select {
  display: flex;
}

.blog-search-and-select>div:first-child {
  margin-right: 20px;
}

.blog-table-container {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 8px;
}

.upload-image-button {
  position: absolute;
  right: 40px;
  top: 70px;
  z-index: 1;
}

.add-blog-back-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #000;
}

.add-blog-back-btn:focus {
  color: #4678EE;
}

.add-blog-form-row {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 30px;
}

.add-blog-form-item {
  width: 50%;
  margin: 0
}

.add-faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}