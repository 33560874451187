@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@300;400;500;700&family=Lora:ital@1&display=swap");

.side-bar {
  font-family: Inter, sans-serif;
  width: 240px;
  height: 100%;
  padding: 2.1rem 1.2rem;
  border-radius: 0px 4px 4px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  transition: 0.4s all ease;
  top: 0;
  z-index: 10;
  padding-bottom: 0px;
}

.menu {
  position: absolute;
  left: 0;
  transition: 0.4s all ease;
}

.logo-name-wrapper {
  position: relative;
  margin-bottom: 20px;
  display: flex;
}

.logo-name {
  display: flex;
  align-items: center;
}

.logo-name__name {
  margin-left: 0.9rem;
  white-space: nowrap;
}

.logo-name__button {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 1.8rem;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.logo-name__icon {
  font-size: 18px;
  color: #000;
}

.chat-list {
  padding: 14px 0;
  font-size: 1.2rem;
}

.chat-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2.1rem;
  cursor: pointer;
}

.chat-item-avatar {
  object-fit: cover;
  object-position: top;
  height: 2.1rem;
  width: 2.1rem;
  border-radius: 50%;
  border: 1px solid grey;
}

.chat-item-name {
  white-space: nowrap;
  transition: opacity 0.5s ease;
  opacity: 1;
  margin-left: 8px;
  display: flex;
  width: 125px;
  justify-content: space-between;
  align-items: center;
}

/* .tooltip {
  position: absolute;
  top: -2.1rem;
  left: 54px;
  background-color: white;
  color: #000;
  border-radius: 0.4rem;
  padding: 0.5rem 0.9rem;
  font-size: 14px;
  transition: 0s;
  opacity: 0;
  width: max-content;
  display: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
} */

/* .features-item:hover .tooltip {
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  opacity: 1;
} */

.side-bar.collapse {
  width: 64px;
}

.side-bar.collapse .tooltip {
  display: none;
}

.features-item:hover #tooltip-hovered {
  display: block;
}

.side-bar.collapse .logo-name,
.side-bar.collapse .features-item-text,
.side-bar.collapse .chat-item-name {
  opacity: 0;
  pointer-events: none;
}

.side-bar.collapse .features-item-text {
  display: none;
}

.side-bar.collapse .logo-name__button {
  right: 0.5rem;
}

.bottom-profile {
  position: absolute;
  bottom: 0px;
  left: 25px;
  display: flex;
}

.user-icon-name-numbrt {
  display: flex;
  flex-direction: column;
}

.user-icon-name {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.user-icon-name-number {
  color: #555;
  font-size: 12px;
  font-family: Inter;
}

.three-dots-wrap {
  transform: rotate(90deg);
  font-size: 24px;
  cursor: pointer;
}

/* .logout_container {
  position: absolute;
  top: 40px;
  right: -110px;
  width: 85px;
} */

/* .logout_container>button {
  display: block;
  padding: 8px 0;
  font-size: 14px;
  border: none;
  background: #f5f5f5;
  outline: none;
  width: 100%;
  text-align: center;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
  border-radius: 2px;
  cursor: pointer;
}

.logout_container>button::before {
  content: "";
  position: absolute;
  top: -15px;
  left: 80%;
  transform: translateX(-80%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fff;
} */