.bulk-messages-container {
  margin: 40px 40px 40px 105px;
}

.left-side {
  width: 600px;
}

.ant-upload-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.ant-upload-hint {
  width: 400px;
  margin: 4px auto;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.uploader-header {
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;
}

.send_button {
  text-align: end;
  margin-top: 40px;
}