.manual-paymeny-main {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.manual-paymeny-item p {
	color: var(--Text-colorText, rgba(0, 0, 0, 0.88));
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 5px;
}

.manual-paymeny-item-button {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 20px;
}
