#edit-lab {
	width: 100%;
	overflow-y: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

#edit-lab::-webkit-scrollbar {
	display: none;
}

.ant-radio-inner::after {
	border-radius: 8px;
}

.edit-lab-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-top: 0px;
	padding-top: 0px;
}

.edit-lab-item {
	margin-bottom: 15px;
}

.edit-lab-item p {
	color: var(--Text-colorText, rgba(0, 0, 0, 0.88));
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 5px;
}
