.subscription-section-container {
    margin: 40px 40px 40px 105px;
}

.subscription-section-date{
    display: flex;
    justify-content: flex-end;
}

.subscription-data{
    display: flex;
    text-align: center;
    margin-top: 20px;
    justify-content: center;
    gap: 20px;
}
.subscription-data-card{
    flex-grow: 1;
}

.subscription-table-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}
.subscription-table-header{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}