#components-form-demo-normal-login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flabs-logo-login-form {
  margin-bottom: 40px;
  text-align: center;
}

#components-form-demo-normal-login .login-form {
  width: 300px;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.give-margin-from-login_button {
  margin-bottom: 10px !important;
}

.ant-input-affix-wrapper {
  border-radius: 4px !important;
}
