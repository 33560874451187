.blog-section-container {
  margin: 40px 30px 40px 110px;
}

.blog-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.blog-search-and-select {
  display: flex;
}

.blog-search-and-select>div:first-child {
  margin-right: 20px;
}

.blog-table-container {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 8px;
}

.upload-image-button {
  position: absolute;
  right: 40px;
  top: 70px;
  z-index: 1;
}

.add-blog-back-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #000;
}

.add-blog-back-btn:focus {
  color: #4678EE;
}

.add-blog-form-row {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 30px;
}

.add-blog-form-item {
  width: 50%;
  margin: 0
}

.add-faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.input_border {
  border-radius: 4px !important;
}

.my_select_container {
  border-radius: 4px !important;
  background-color: white;
  border: 1px solid #d9d9d9;


  &:hover {
    border-color: #4f92ef !important;
    border-top-color: rgb(53, 103, 150);
    border-right-color: rgb(53, 103, 150);
    border-bottom-color: rgb(53, 103, 150);
    border-left-color: rgb(53, 103, 150);
  }
}

/* .my_select_container :hover{
  border: 1px solid black;
} */