/* Lab Analytics */
.lab_analytics_container {
	margin: 40px 0px 40px 105px;
	display: flex;
	justify-content: space-between;
}

.total__labs {
	border: 1px solid #dededf;
	border-radius: 4px;
	width: 196px;
	padding: 24px 20px;
}

.bold_text {
	font-weight: 400;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.45);
}

.lab_quantity {
	font-weight: 700;
	font-size: 44px;
	color: #2c89e5;
}

.lab_subscription_container {
	border: 1px solid #dededf;
	border-radius: 4px;
	padding: 24px 40px;
	display: flex;
}

.subs_info_container {
	display: flex;
}

/* Overriden class of antd */
.ant-progress-circle-trail {
	stroke: #fff !important;
}

.ant-progress-text {
	font-size: 20px !important;
	font-weight: 600 !important;
}

.ant-input-affix-wrapper:not(:last-child) {
	border-start-start-radius: 2px !important;
	border-end-start-radius: 2px !important;
	border-start-end-radius: 0 !important;
	border-end-end-radius: 0 !important;
}

.circular_border {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
}

.ant-progress-inner {
	border: 1px solid #d9d9d9 !important;
}

.subs_plan {
	font-weight: 400;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.45);
	margin-bottom: 10px;
}

.subs_lab_quantity {
	font-weight: 700;
	font-size: 32px;
	color: #2c89e5;
}

.separation__line {
	width: 1px;
	height: 70px;
	background: rgba(0, 0, 0, 0.12);
	margin: 0 50px;
}

/* Lab Information Table css */
.lab_info_container {
	margin: 30px 0px 30px 105px;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
}

.lab_info_container_top {
	margin: 30px 10px 30px 105px;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
}

.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
	display: none;
}

.virtual-grid {
	height: auto !important;
}

.table-header-container {
	display: flex;
	margin: 14px 20px;
	justify-content: space-between;
}

.ant-input,
.ant-btn,
.ant-select-selector,
.ant-picker-range {
	border-radius: 2px !important;
}

.table__container {
	padding-bottom: 50px;
}

.table-header-right>span:first-child {
	margin-right: 10px;
}

.popup__menu {
	border-radius: 0 !important;
	padding-left: 0;
	padding-right: 0;
}

.popup__menu * {
	border-radius: 0 !important;
}

@media screen and (max-width: 768px) {
	.lab_info_container {
		margin: 10px 4px 40px 88px;
		width: 100%;
	}

	.table-header-container {
		gap: 5px;
		margin: 14px 4px;
		width: 100%;
	}

	.table-header-right {
		display: flex;
		gap: 5px;
	}

	.table__container {
		width: 100%;
	}
}